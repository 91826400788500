import React from "react";
import styled from "styled-components";
import { TbTruckDelivery } from "react-icons/tb";
import { GiReceiveMoney } from "react-icons/gi";
import { RiSecurePaymentLine } from "react-icons/ri";

const Services = () => {
  return (
    <Wrapper>
      <div className="container">
        <div className="grid grid-three-column">
          <div className="services-1">
            <div>
              <TbTruckDelivery className="icon" />
              <h3>Super Fast Delivery</h3>
            </div>
          </div>
          <div className="services-2">
            <div>
              <GiReceiveMoney className="icon" />
              <h3>Attractive Discounts</h3>
            </div>
            {/* <div className="services-column-2">
              <MdSecurity className="icon" />
              <h3>Non-contact shipping</h3>
            </div>
            <div className="services-column-2">
              <GiReceiveMoney className="icon" />
              <h3>Genuine Resonable Prices</h3>
            </div> */}
          </div>
          <div className="services-3">
            <div>
              <RiSecurePaymentLine className="icon" />
              <h3>Super Secure Payment</h3>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

// const Wrapper = styled.section`
//   padding: 9rem 0;
//   .grid {
//     gap: 4.8rem;
//   }
//   .services-1,
//   .services-2,
//   .services-3 {
//     width: auto;
//     height: 30rem;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-content: center;
//     background: ${({ theme }) => theme.colors.bg};
//     text-align: center;
//     border-radius: 2rem;
//     box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
//   }
//   // .services-2 {
//   //   gap: 4rem;
//   //   background-color: transparent;
//   //   box-shadow: none;
//   //   .services-column-2 {
//   //     background: ${({ theme }) => theme.colors.bg};
//   //     display: flex;
//   //     flex-direction: row;
//   //     flex: 1;
//   //     justify-content: center;
//   //     align-items: center;
//   //     border-radius: 2rem;
//   //     box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
//   //     div {
//   //       display: flex;
//   //       flex-direction: row;
//   //       justify-content: center;
//   //       align-items: center;
//   //       gap: 1rem;
//   //     }
//   //   }
//   // }
//   h3 {
//     margin-top: 1.4rem;
//     font-size: 2rem;
//   }
//   .icon {
//     /* font-size: rem; */
//     width: 8rem;
//     height: 8rem;
//     padding: 2rem;
//     border-radius: 50%;
//     background-color: #fff;
//     color: #5138ee;
//   }
// `;

const Wrapper = styled.section`
  padding: 9rem 0;

  .grid {
    gap: 4.8rem;
  }

  .services-1,
  .services-2,
  .services-3 {
    width: auto;
    height: 30rem; /* Default height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.bg};
    text-align: center;
    border-radius: 2rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }

  h3 {
    margin-top: 1.4rem;
    font-size: 2rem;
  }

  .icon {
    width: 8rem;
    height: 8rem;
    padding: 2rem;
    border-radius: 50%;
    background-color: #fff;
    color: #5138ee;
  }

  @media (max-width: 768px) {
    .services-1,
    .services-2,
    .services-3 {
      height: 20rem; /* Reduced height for smaller screens */
    }

    h3 {
      font-size: 1.6rem; /* Adjust font size for smaller screens */
    }

    .icon {
      width: 6rem;
      height: 6rem;
      padding: 1.5rem;
    }
  }

  @media (max-width: 480px) {
    .services-1,
    .services-2,
    .services-3 {
      height: 15rem; /* Further reduced height for very small screens */
    }

    h3 {
      font-size: 1.4rem; /* Further adjust font size for very small screens */
    }

    .icon {
      width: 5rem;
      height: 5rem;
      padding: 1rem;
    }
  }
`;

export default Services;
