import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../styles/Button";

const HeroSection = ({ myData }) => {
  const { name } = myData;
  return (
    <Wrapper>
      <div className="container">
        <div className="grid grid-two-column">
          <div className="hero-section-data">
            <p
              className="intro-data"
              style={{
                color: "#ee4f10",
              }}
            >
              Welcome to
            </p>
            <h1>{name}</h1>
            <p>
              At {name}, we are committed to making healthcare simple and
              accessible.
            </p>
            <ul>
              <li>
                <strong>Seamless Medicine Delivery:</strong> We partner with
                local chemist shops to deliver medicines right to your doorstep.
                Simply upload your prescription, and our trusted network ensures
                timely and accurate delivery.
              </li>
              <li>
                <strong>Reliable Lab Test Services:</strong> Through our
                collaboration with Healthians Labs, we offer professional and
                trustworthy diagnostic services. Book your lab tests
                effortlessly and receive accurate results.
              </li>
            </ul>
            <p>
              Experience hassle-free healthcare with {name}, your trusted
              partner in well-being.
            </p>
            <NavLink to="/">
              <Button>Shop Now</Button>
            </NavLink>
          </div>
          <div className="hero-section-image">
            <figure>
              <img
                src="images/medicine_card.jpg"
                alt="medicine"
                className="img-style"
              ></img>
            </figure>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 12rem 0;

  img {
    min-width: 10rem;
    height: 10rem;
  }

  .hero-section-data {
    p {
      margin: 2rem 0;
    }
    h1 {
      text-transform: capitalize;
      font-weight: bold;
    }
    .intro-data {
      margin-bottom: 0;
    }
    ul {
      margin: 1.5rem 0;
      padding-left: 2rem;
      list-style-type: disc;
      li {
        font-size: 1.65rem; /* Increased font size for better visibility */
        margin: 1rem 0;
        line-height: 1.8; /* Adjusted line height for readability */
        color: #333; /* Improved text color for contrast */
      }
    }
  }

  .hero-section-image {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  figure {
    position: relative;
    &::after {
      content: "";
      width: 60%;
      height: 80%;
      background-color: rgba(200, 80, 10, 0.4);
      position: absolute;
      left: 50%;
      top: -5rem;
      z-index: -1;
    }
  }

  .img-style {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid {
      gap: 10rem;
    }
    figure::after {
      content: "";
      width: 50%;
      height: 100%;
      left: 0;
      top: 10%;
      background-color: rgba(200, 80, 10, 0.4);
    }
  }
`;

export { HeroSection };
