// WhatsAppButton.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const WhatsAppButton = () => {
  const handleClick = () => {
    window.open(`https://wa.me/${process.env.REACT_APP_Whatsapp_no}`, "_blank");
  };

  return (
    <div
    // style={{
    //   position: "fixed",
    //   bottom: "20px",
    //   right: "20px",
    //   zIndex: 1000,
    // }}
    >
      <button
        onClick={handleClick}
        style={{
          backgroundColor: "#25D366",
          color: "white",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "none",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
        }}
      >
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </button>
    </div>
  );
};

export default WhatsAppButton;
