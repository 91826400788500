import { useProductContext } from "../../context/productcontext";
import medicine_img from "../../utils/medicine_card.jpg";
import lab_img from "../../utils/lab_card.jpg";
import styled from "styled-components";
// import Product from "../product/Product";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";

const FeatureProduct = () => {
  const { isLoading } = useProductContext();

  if (isLoading) {
    return <div>.....loading</div>;
  }

  return (
    <Wrapper className="section">
      <div className="container">
        <div
          className="intro-data"
          style={{
            color: "#ee4f10",
          }}
        >
          check Now!
        </div>
        <div className="common-heading">Our Services</div>
        <div className="grid grid-two-column">
          <MDBCard>
            <figure>
              <img src={medicine_img} alt="medicine" />
            </figure>
            {/* <MDBCardImage src={medicine_img} position="top" alt="..." /> */}
            <MDBCardBody>
              <MDBCardTitle
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                Medicine
              </MDBCardTitle>
              <MDBCardText>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>

          <MDBCard>
            <figure>
              <img src={lab_img} alt="medicine" />
            </figure>
            {/* <MDBCardImage src={lab_img} position="top" alt="..." /> */}
            <MDBCardBody>
              <MDBCardTitle
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                Labs & Tests
              </MDBCardTitle>
              <MDBCardText>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 9rem 0;
  background-color: ${({ theme }) => theme.colors.bg};
  .container {
    max-width: 120rem;
  }

  figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(1.2);
    }
    img {
      max-width: 90%;
      margin-top: 1.5rem;
      height: 20rem;
      transition: all 0.2s linear;
    }
    .caption {
      position: absolute;
      top: 15%;
      right: 10%;
      text-transform: uppercase;
      background-color: ${({ theme }) => theme.colors.bg};
      color: ${({ theme }) => theme.colors.helper};
      padding: 0.8rem 2rem;
      font-size: 1.2rem;
      border-radius: 2rem;
    }
  }

  .card {
    background-color: #fff;
    border-radius: 1rem;

    .card-data {
      padding: 0 2rem;
    }

    .card-data-flex {
      margin: 2rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h3 {
      color: ${({ theme }) => theme.colors.text};
      text-transform: capitalize;
    }

    .card-data--price {
      color: ${({ theme }) => theme.colors.helper};
    }

    .btn {
      margin: 2rem auto;
      background-color: rgb(0 0 0 / 0%);
      border: 0.1rem solid rgb(200 100 0);
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: rgb(200 100 0);
      }
      &:hover a {
        color: #fff;
      }
      a {
        color: rgb(200 100 0);
        font-size: 1.4rem;
      }
    }
  }
`;

export default FeatureProduct;
